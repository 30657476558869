import BuriedPoint from '@/script/burialPoint'
export default { 
  data() {
    return {
      // 埋点使用
      buriedPoint: null,
      // 赞助商图片
      sponsorIcon:[
        require('@/assets/image/xiao/xiao2.png'),
        require('@/assets/image/xiao/xiao1.png'),
        require('@/assets/image/xiao/xiao4.png'),
        // require('@/assets/image/xiao/xiao3.png')
      ],
      // 赞助商
      sponsorArr:[
        {
          leftValue:'贊助機構：',
          rightValue:'曾憲備慈善基金'
        },
        {
          leftValue:'主辦機構：',
          rightValue:'曾憲備慈善基金、新界校長會、中文路（香港）有限公司'
        },
        {
          leftValue:'合辦機構：',
          rightValue:'郭玲麗立法會議員辦事處、活力慈善基金、梨木樹苗趣社'
        },
        {
          leftValue:'承辦機構：',
          rightValue:'中文路（香港）有限公司'
        },
        {
          leftValue:'支持機構：',
          rightValue:'民建聯、文冬漢語普通話中心、北京廣播電視台北京廣播影視培訓中心、香港浙江省同鄉會聯合會、香港福建社團聯會新界東北分會、香港普通話專業協會、朱偉林區議員辦事處、劉松剛區議員辦事處、靳彤彤區議員辦事處、李細燕區議員辦事處、邱少雄區議員辦事處、林翠玲區議員辦事處、周劍豪區議員辦事處、陳孟宜區議員辦事處'
        }
      ],
      // 比赛类型
      currentCompetition: '1',
      competitionType:[
        {
          title:'個人賽',
          value:'1'
        },
        {
          title:'團體赛',
          value:'2'
        },
      ],
      // 個人賽时间线
      timeLine1: [
        {
          name: '正式報名',
          tiem: `3月31日<br/> 至 <br/>5月10日 21:00`,
          select: true,
          xuanSelect: true,
          content: {
            title: '正式報名',
            value: '官方活動網站或中文路APP：ChineseRd Plus'
          }
        },
        {
          name: '初賽',
          tiem: `3月31日<br/> 至 <br/>5月10日 23:59`,
          select: false,
          xuanSelect: false,
          content: {
            title: '中文路APP',
            value: `參賽者需使用官方指定誦材，通過中文路App進行普通話語音測試，每人共有<span style="color:red;">5次</span>測試機會，將自動選擇最高分的1次參與評選，各組別按分數排名，<span style="color:red;">前100名</span>將獲得入圍複賽資格，參與複賽影片錄製。`
          }
        },
        {
          name: '公佈複賽入圍名單',
          tiem: `5月12日`,
          select: false,
          xuanSelect: false,
          content: {
            title: '官方活動網站公佈名單或登入中文路App查詢個人入圍情況',
            value: '請留意電郵或短信提醒並查閱入圍結果。'
          }
        },
        {
          name: '複賽',
          tiem: `5月12日<br/> 至 <br/>6月8日 23:59`,
          select: false,
          xuanSelect: false,
          content: {
            title: '官方活動網站或中文路APP',
            value: `
            各組參賽者需使用官方指定誦材，並錄製朗誦影片，可通過官方活動網站或中文路App提交影片。各組別按分數排名，<span style="color:red;">前6名</span>將進入決賽，若同分同名次，可同時晉級決賽。<span style="color:red;">第7名至第10名將獲優異獎</span>。`
          }
        },
        {
          name: '公佈決賽入圍名單',
          tiem: `6月16日`,
          select: false,
          xuanSelect: false,
          content: {
            title: '官方活動網站公佈名單',
            value: '請留意電郵或短信提醒並查閱入圍結果。'
          }
        },
        {
          name: '決賽暨頒獎典禮',
          tiem: `7月5日`,
          select: false,
          xuanSelect: false,
          index: 6,
          content: {
            title: '（請留意主辦方最新公佈的安排為準）',
            value: '將於線下場所進行現場對決比賽，上半場決賽，下半場頒獎典禮。'
          }
        }
      ],
      // 團體赛时间线
      timeLine2: [
        {
          name: '正式報名',
          tiem: `3月31日<br/> 至 <br/>6月8日 21:00`,
          select: true,
          xuanSelect: true,
          content: {
            title: '官方活動網站',
            value: '在官方活動網站提交報名參賽隊伍的總人數、選擇誦材篇章篇目。'
          }
        },
        {
          name: '初賽',
          tiem: `3月31日<br/> 至 <br/>6月8日 23:59`,
          select: false,
          xuanSelect: false,
          content: {
            title: '官方活動網站',
            value: `在官方活動網站提交初賽影片。`
          }
        },
        {
          name: '公佈決賽入圍名單',
          tiem: `6月16日`,
          select: false,
          xuanSelect: false,
          index: 5,
          content: {
            title: '官方活動網站公佈入圍隊伍名單',
            value: '請留意電郵或短信提醒並查閱入圍結果。'
          }
        },
        {
          name: '決賽暨頒獎典禮',
          tiem: `7月5日`,
          select: false,
          xuanSelect: false,
          index: 6,
          content: {
            title: '（請留意主辦方最新公佈的安排為準）',
            value: '將於線下場所進行現場對決比賽，上半場決賽，下半場頒獎典禮。'
          }
        }
      ],
      // 個人賽参赛资格
      qualifying1:  {
        info: [
          '參賽者須為2024-2025學年在香港中、小學及幼稚園（含特殊學校）就讀全日制或半日制，或在大灣區港人子弟學校就讀且持有香港身份證的學生。',
          '歡迎學校或非牟利機構統一提交學生報名表。',
          '非華裔學生請選擇「飛躍組」，特殊學校學生請選擇「閃亮組」參賽，兩組均需通過學校/機構統一提交學生報名表。'
        ],
        group: [
          {
            level: 'K1-K2',
            name: '幼稚園初級組',
          },
          {
            level: 'K3',
            name: '幼稚園高級組',
          },
          {
            level: 'P1-P2',
            name: '小學初級組',
          },
          {
            level: 'P3-P4',
            name: '小學中級組',
          },
          {
            level: 'P5-P6',
            name: '小學高級組',
          },
          {
            level: 'S1-S3',
            name: '中學組',
          },
          {
            level: '非華裔',
            name: '飛躍組',
          },
          {
            level: '特殊學校',
            name: '閃亮組',
          }
        ]
      },
      // 團體参赛资格
      qualifying2:  {
        info: [
          '參賽隊員須為2024-2025學年在香港教育局認可學校（中、小學及幼稚園）就讀全日制或半日制，或在粵港澳大灣區就讀且持有香港身份證的學生。',
          `各學校或非牟利機構<span style="color:#f97226;">每個組別最多限報1隊</span>，每隊人數5至30人，成員不得跨隊參賽。`,
          '參賽隊伍可越級挑戰，以隊員最高年級定組別。如：A隊有6位參賽成員，其中2位為小學二年級學生，4位為幼稚園學生，須報名小學初級組。'
        ],
        group: [
          {
            level: 'K1-K3',
            name: '幼稚園組',
          },
          {
            level: 'P1-P3',
            name: '小學初級組',
          },
          {
            level: 'P4-P6',
            name: '小學高級組',
          },
          {
            level: 'S1-S6',
            name: '中學組',
          },
        ]
      }
    };
  },
  computed: {
    competitionTimeLine () {
      return this.currentCompetition === '1' ? this.timeLine1 : this.timeLine2
    },
    competitionQualifying () {
      return this.currentCompetition === '1' ? this.qualifying1 : this.qualifying2
    }
  },
  components: { 
  },
  watch: {},
  created() { 
    
  },
  beforeDestroy() {
    // this.buriedPoint.addPageData(0)
  },
  mounted() {},
  methods: {
    changeCompetition(value) {
      this.currentCompetition = value
    },
    home_mdInitBut(){   
      this.buriedPoint = new BuriedPoint({
        page: 'web_zjb_recital2_competition',
        parm:JSON.stringify({'type':this.getQueryString('c')})
      })
      this.buriedPoint.init()
    },
    getQueryString: function(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    home_mdevInit(){ 
      // 点击埋点  无初始化情况
      this.buriedPoint = new BuriedPoint()
      this.buriedPoint.init()
      this.buriedPoint.addEventData({
        event: 'web_talk_recital2_click',
        parm:{'type':localStorage.getItem('c')}
      })
    },
    home_mdBut(){
      // 点击埋点
      this.buriedPoint.addEventData({
        event: 'web_talk_recital2_click',
        parm:JSON.stringify({'type':localStorage.getItem('c')})
      })
    }
  },
}; 