<!-- 1440 安全  -->
<template>
  <section class="header_view">
    <div class="header">
      <div class="headerBox">
        <div class="boxLeft">
          <ul>
            <li>
              <img :src="require('@/assets/image/icon/logo2.png')" alt="" />
            </li>
            <li
              v-for="(item, index) in nav"
              :key="index"
              :class="select == item.id ? 'select' : ''"
              @click="but(item)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="boxRight">
          <span @click="aheftBut()">個人報名</span>
        </div>
        <div class="boxRight" style="margin-left: 20px">
          <span @click="toAdminRegister()">學校報名</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { speecheduUrl } from '@/utils/link'
export default {
  name: 'views',
  data() {
    return {
      nav: [
        {
          id: '0',
          name: '活動首頁',
          path: '/',
          href: null,
          type: 1 //1是本地跳轉   2,pdf預覽   3,外鏈
        },
        // {
        // 	id:1,
        // 	name:'比賽指引',
        // 	path:'/',
        //   href:null,
        // },
        {
          id: '2',
          name: '比賽誦材',
          path: '/material',
          href: null,
          type: 1 //1是本地跳轉   2,pdf預覽   3,外鏈
        },
        {
          id: '3',
          name: '成績公示',
          path: '/achievement',
          href: null,
          type: 1 //1是本地跳轉   2,pdf預覽   3,外鏈
        },
        {
          id: '4',
          name: '比賽章程',
          path: '/rules',
          href: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2024-04/e7bcc39b7ecd867074ab6a773fc3c0a0.pdf',
          type: 1 //1是本地跳轉   2,pdf預覽   3,外鏈
        },
        {
          id: '5',
          name: '比賽APP下載',
          path: '/',
          href: 'https://app.chineserd.com',
          type: 3 //1是本地跳轉   2,pdf預覽   3,外鏈
        }
      ]
    }
  },
  props: {
    select: {
      type: String,
      default: '0'
    }
  },
  computed: {},
  components: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    getQueryString: function (name) {
      return (
        decodeURIComponent(
          (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
            location.href
          ) || [, ''])[1].replace(/\+/g, '%20')
        ) || null
      )
    },
    toAdminRegister() {
      // this.$dialog
      //   .alert({
      //     title: '提示',
      //     message: '報名未開始',
      //     confirmButtonText: '確認'
      //   })
      //   .then(() => {
      //     // on close
      //   })
      //   return
      // 跳管理后台注册页
      window.open(speecheduUrl, '_blank')
    },
    aheftBut(e) {
      // this.$dialog
      //   .alert({
      //     title: '提示',
      //     message: '報名未開始',
      //     confirmButtonText: '確認'
      //   })
      //   .then(() => {
      //     // on close
      //   })
      if (
        this.getQueryString('c') != null ||
        this.getQueryString('c') != undefined
      ) {
        this.$router.push('/signup?c=' + this.getQueryString('c'))
      } else {
        this.$router.push('/signup')
      }
    },
    heftBut(e) {
      this.$router.push(e)
    },
    but(e) {
      if (e.id == this.select) {
        console.log('不生效')
        this.popupShow = false
      } else {
        switch (e.type) {
          case 1:
            this.$router.push(e.path)
            break
          case 2:
            let baseurl =
              'http://chineserd.cn/h5/html/regulations/index.html?url=' + e.href
            window.open(baseurl, '_blank')
            break
          case 3:
            window.open(e.href, '_blank')
            break
          default:
            break
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header_view {
  width: 100%;
  height: 80px;
  position: relative;

  .header {
    position: sticky;
    width: 100%;
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    position: fixed;
    z-index: 99999;
    background: #ff891f;
    box-shadow: 0px 4px 20px 0px rgba(164, 79, 0, 0.15);

    .headerBox {
      max-width: 1246px;
      height: 80px;
      margin: auto;
      display: flex;
      justify-content: space-between;

      .boxLeft {
        height: 100%;

        ul {
          height: 100%;
          display: flex;

          li {
            width: 160px;
            height: 100%;
            font-size: 20px;
            font-family: PingFang SC-Semibold, PingFang SC;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            img {
              width: 80px;
            }
          }

          .select {
            font-weight: bold;
            background: url('~@/assets/image/hodgepodge/tabBut.png') no-repeat;
            background-size: 100% 100%;
          }
        }
      }

      .boxRight {
        height: 100%;
        display: flex;
        align-items: center;
        color: #ffffff;
        cursor: pointer;
        position: relative;
        left: 50px;
        span {
          min-width: 136px;
          height: 48px;
          background: url('~@/assets/image/icon/but.png') no-repeat;
          background-size: 100% 100%;
          // background: #d0d0d0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 15px;
          // box-shadow: 0px 4px 9px 0px rgba(244, 86, 5, 0.4);
          box-shadow: 0px 4px 9px 0px rgba(208, 208, 208, 0.4);
          border-radius: 80px;
          padding: 15px;
          box-sizing: border-box;
        }
      }
    }
  }
}
</style>
